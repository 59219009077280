<template>
    <div class="p-grid panel-demo">
        <div class="p-col-12 p-md-8">
            <div class="card p-fluid">
                <h4>Your ArenaCX Marketplace Portal</h4>
            </div>
            <div class="card p-fluid">
                <h6><i class="pi pi-user-edit p-mr-2 p-p-2 blue-bgcolor white-color widget-list-item-radius"></i>Partner
                    Profile</h6>
                <div class="para">Use your <router-link class="routerLink" :to="{ path: '/partner/profile' }">Partner
                        Profile</router-link> page to maintain core information about your business that we will use to
                    accurately represent you to current and prospective Members.</div>
            </div>
            <div class="card p-fluid">
                <h6><i class="pi pi-info-circle p-mr-2 p-p-2 orange-bgcolor white-color widget-list-item-radius"></i>RFP Answer Bank</h6>
                <div class="para">Use the <router-link class="routerLink" :to="{ path: '/partner/answerbank' }">
                        Answer Bank</router-link> to capture detailed competitive data about your firm's strengths, history
                        and capabilities.  These are used to automatically pre-fill 150+ RFP questions using our RFP engine,
                        saving you time and minimizing repetitive input.
                        </div>
            </div>
            <div class="card p-fluid">
                <h6><i class="pi pi-thumbs-up p-mr-2 p-p-2 pink-bgcolor white-color widget-list-item-radius"></i>Marketing Profile
                </h6>
                <div class="para">Fill out your <router-link class="routerLink" :to="{ path: '/partner/marketing' }">Marketing
                        Profile</router-link> template so that ArenaCX can best share your strengths and history with 
                        prospective Members.</div>
            </div>
            <div class="card p-fluid">
                <h6><i class="pi pi-dollar p-mr-2 p-p-2 green-bgcolor white-color widget-list-item-radius"></i>Billing
                </h6>
                <div class="para">Visit your <router-link class="routerLink" :to="{ path: '/account/billing' }">Billing
                        Page</router-link> to review your bills and payment status.</div>
            </div>
            <div v-if="userIsACX" class="card p-fluid">
                <h6><i
                        class="pi pi-exclamation-circle p-mr-2 p-p-2 lightblue-bgcolor white-color widget-list-item-radius"></i>Your
                    Opportunities</h6>
                <div class="para">Review your active <router-link class="routerLink"
                        :to="{ path: '/partner/opportunities' }">Opportunities (RFPs)</router-link> and complete them
                    ahead of their due dates.</div>
            </div>
            <div v-if="userIsACX" class="card p-fluid">
                <h6><i class="pi pi-book p-mr-2 p-p-2 orange-bgcolor white-color widget-list-item-radius"></i>Document
                    Library</h6>
                <div class="para">Your <router-link class="routerLink" :to="{ path: '/account/documents' }">library
                    </router-link> of important Marketplace documents including contracts, SOWs and other important
                    papers</div>
            </div>
        </div>
        
        <div  class="p-col-12 p-md-4">
            <div class="card p-fluid">
                <div class="para">Your portal helps you to review and manage your activity and performance in the
                    Marketplace.</div>
                <div class="para">
                    We are adding features here all the time, and have a lot in store. Plans include:
                    <ul>
                        <li>Embedded "Slack-like" chat.</li>
                        <li>Embedded "Zoom-like" video conferencing.</li>
                        <li>Ability to share your calendar availability online to other Marketplace users.</li>
                        <li>Dashboards showing your history and performance in all your arenas.</li>
                        <li>Opportunity status and history, so you can track the RFPs you have been invited to.</li>
                    </ul>
                </div>
            </div>

        </div>

        <div v-if="userIsACX" class="p-col-12 p-md-4">
            <div class="card p-fluid">
                <h5>Partner Profile Status</h5>
                <div class="para">Keep your Partner Profile up to date, so that we can better match you with the best
                    opportunities.</div>
                <div class="para">Click to update your Partner Profile.</div>

                <div class="p-col-12 p-lg-12">
                    <ul class="widget-list">
                        <li class="p-d-flex p-py-3">
                            <div class="p-d-flex p-ai-center">
                                <i class="pi pi-star p-p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                                <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                    <div class="profileStatusTitle">Firmographic Details</div>
                                    <small class="muted-text">Percent complete</small>
                                </div>
                            </div>
                            <Badge class="p-ml-auto p-mr-3" severity="success">92%</Badge>
                        </li>

                        <li class="p-d-flex p-py-3">
                            <div class="p-d-flex p-ai-center">
                                <i
                                    class="pi pi-file-excel p-p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                                <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                    <div class="profileStatusTitle">Opportunity Match Data %</div>
                                    <small class="muted-text">Percent complete</small>
                                </div>
                            </div>
                            <Badge class="p-ml-auto p-mr-3" severity="info">71%</Badge>
                        </li>

                        <li class="p-d-flex p-py-3">
                            <div class="p-d-flex p-ai-center">
                                <i class="pi pi-image p-p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                                <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                    <div class="profileStatusTitle">RFP Answer Bank %</div>
                                    <small class="muted-text">Percent complete</small>
                                </div>
                            </div>
                            <Badge class="p-ml-auto p-mr-3" severity="warning">35%</Badge>
                        </li>

                        <li class="p-d-flex p-py-3">
                            <div class="p-d-flex p-ai-center">
                                <i class="pi pi-file p-p-2 bluegrey-bgcolor white-color widget-list-item-radius"></i>
                                <div :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                                    <div class="profileStatusTitle">Stale Data for Review</div>
                                    <small class="muted-text">Check a few today!</small>
                                </div>
                            </div>
                            <Badge class="p-ml-auto p-mr-3" severity="info">16&nbsp;</Badge>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import MemberHomeService from '../service/MemberHomeService';
import { getAccountId } from '../service/common';
import CurrentUserService from '../service/CurrentUserService';
//import { reactive } from 'vue';

export default {
    setup() {
        const featuresByCategory = ref({});
        return {
            featuresByCategory,
        };
    },
    data() {
        return {
            featureData: null,
            //featuresByCategory: {},
            partnerId: null,
            oddity: 0,
            userIsACX: false
        };
    },

    memberHomeService: null,
    tempFeaturesByCategory: null,

    created() {
        this.partnerId = getAccountId();
        this.userService = new CurrentUserService();
    },

    async updated() {
        this.partnerId = getAccountId();
    },

    async mounted() {
        this.partnerId = getAccountId();
        this.userIsACX = this.userService.marketplaceUser.userRole.roleKey.startsWith('acx:');
    },

    watch: {
        partnerId: async function (newValue) {
            if (this.partnerId) {
                this.featuresByCategory = await this.loadFeatures(newValue);
                //this.featuresByCategory = this.tempFeaturesByCategory;
                //this.oddity = this.featuresByCategory[2].features[0].accountFeatures.length;
            }
        },
        featuresByCategory: function () {
            console.log('watcher for featuresByCategory fired');
        },
    },

    methods: {
        loadFeatures: async function (newPartnerId) {
            this.memberHomeService = new MemberHomeService();
            await this.memberHomeService.getMemberHomeFeatureData(newPartnerId).then((data) => (this.featureData = data));
            return this.getByCategory();
        },

        toggle(event) {
            this.$refs.menu.toggle(event);
        },

        toggleMsg(event) {
            this.$refs.op.toggle(event);
        },

        // Need to group them by category so that UI can group them
        getByCategory() {
            var dataByCategory = [];

            var objFind = {};
            objFind.categoryId = 0;
            objFind.categoryName = 'Find';
            objFind.features = this.featureData.filter(function (item) {
                return item.category == 0;
            });
            dataByCategory.push(objFind);

            var objManage = {};
            objManage.categoryId = 1;
            objManage.categoryName = 'Manage';
            objManage.features = this.featureData.filter(function (item) {
                return item.category == 1;
            });
            dataByCategory.push(objManage);

            var objOptimize = {};
            objOptimize.categoryId = 2;
            objOptimize.categoryName = 'Optimize';
            objOptimize.features = this.featureData.filter(function (item) {
                return item.category == 2;
            });
            dataByCategory.push(objOptimize);

            return dataByCategory;
        },
    },
};
</script>

<style scoped lang="scss">
.para {
    line-height: 1.5;
    margin: 0;
    margin-bottom: 1em;
    line-height: 1.3em;
}

.serviceOn {
    color: rgb(96, 182, 149);
}

.serviceOff {
    color: lightgrey;
}

.definition {
    font-size: 90%;
    color: #555555;
}

.nounVerb {
    font-weight: bold;
    font-style: italic;
}

.profileStatusTitle {
    text-decoration: underline;
    cursor: pointer;
    color: darkblue;
}

.routerLink {
    text-decoration: underline;
    cursor: pointer;
    color: darkblue;
}
</style>
