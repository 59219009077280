import axios from 'axios';
// import { useStorage } from "vue3-storage";
import CurrentUserService from "../service/CurrentUserService";
const currentUser = new CurrentUserService();

function getAxios() {

	currentUser.refreshCognitoJwt();
	let jwt = currentUser.jwt;

	const instance = axios.create({
		baseURL: process.env.VUE_APP_DOT_NET_BASE_URL,
        withCredentials: false,
	});

	// set common headers
	instance.defaults.headers.common['Authorization'] = jwt;
	instance.defaults.headers.common['Content-Type'] = 'application/json';

	return instance;
}



export default class MemberHomeService {


    async getMemberHomeFeatureData(memberId) {
        
        //console.log('Got inside MemberHomeService.getMemberHomeFeatureData()');

        var result = await getAxios().get(`/Account/${memberId}/featuredata`)
            .then(res => 
                {
                    return res.data;
                })
            .catch(err => {
                console.log("ERROR TIME");
                console.log(err);
            });
        
        //console.log(result);
        return result;         
    }
    



    async getMemberHomeFeatureData_Demo() {
        //console.log("Got inside MemberHomeDataService.getMemberHomeFeatureData_Demo");
        
        var result = await axios.get('assets/demo/data/memberHomeFeatureData.json')
            .then(res => 
                {
                    //console.log("did I get here?");
                    return res.data.root;
                })
            .catch(err => console.log(err));

        //console.log(result);
        return result;
    }

    getDishHomeFeatureData() {
        return axios.get('assets/demo/data/dishHomeFeatureData.json')
                .then(res => res.data.root);
    }

}
